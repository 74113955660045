var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "refFormObserver"
  }, [_c('b-card', {
    staticClass: "mb-50 mb-md-1 border-info-2",
    attrs: {
      "body-class": "p-50"
    }
  }, [_c('b-row', {
    staticClass: "mx-0 mb-50",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    staticClass: "bg-blue-gradient d-flex mb-25 mb-md-0",
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-center align-items-center"
  }, [_c('b-alert', {
    staticClass: "p-75 p-md-1 mr-25 mr-md-75 mb-0",
    staticStyle: {
      "width": "fit-content"
    },
    attrs: {
      "show": "",
      "variant": "primary"
    }
  }, [_c('feather-icon', {
    staticClass: "text-body",
    attrs: {
      "icon": "UsersIcon",
      "size": _vm.isMobileView ? '20' : '30'
    }
  })], 1)], 1), _c('div', {
    staticClass: "d-flex flex-column justify-content-center"
  }, [_c('div', {
    class: "text-airline fw-700 ".concat(_vm.isMobileView ? 'font-medium-4' : 'font-medium-3')
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.passengerInfo')) + " "), _vm.isMobileView ? _c('feather-icon', {
    staticClass: "text-info cursor-pointer",
    attrs: {
      "id": "header-tooltip-mobile-pax",
      "icon": "InfoIcon",
      "size": "16"
    }
  }) : _vm._e()], 1), _vm.isMobileView ? _c('b-tooltip', {
    attrs: {
      "target": "header-tooltip-mobile-pax",
      "triggers": "hover focus",
      "boundary": "window",
      "variant": "info"
    }
  }, [_c('small', [_vm._v(" " + _vm._s(_vm.$t('flight.passengerInfoNote')))])]) : _vm._e(), !_vm.isMobileView ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('flight.passengerInfoNote')) + " ")]) : _vm._e()], 1)]), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center justify-content-md-end w-100",
    attrs: {
      "sm": "12",
      "md": "5"
    }
  }, [_vm.enableQuickName && _vm.getEnv == 'dev' && !_vm.isHideFunction ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(234, 84, 85, 0.15)',
      expression: "'rgba(234, 84, 85, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "rounded-lg mr-50 py-50 py-md-1 d-flex-center border-0 px-1 px-lg-2",
    attrs: {
      "id": "id-icon-quick-input-multiple-pax",
      "variant": "danger"
    },
    on: {
      "click": _vm.fakePaxInfo
    }
  }, [_c('feather-icon', {
    staticClass: "text-white mr-50 d-none d-md-inline",
    attrs: {
      "icon": "ZapIcon",
      "size": "14"
    }
  }), _c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v("Nhập tên siêu nhanh")])], 1) : _vm._e(), _vm.suggestVisible && !_vm.isHideFunction ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(234, 84, 85, 0.15)',
      expression: "'rgba(234, 84, 85, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "rounded-lg mr-50 py-50 py-md-1 d-flex-center border-0 px-1 px-lg-2 pulse-button",
    attrs: {
      "variant": "outline-danger"
    },
    on: {
      "click": function click() {
        return _vm.$bvModal.show('suggest-shop-modal');
      }
    }
  }, [_c('div', {
    staticClass: "d-flex-center gap-1"
  }, [_c('feather-icon', {
    staticClass: "mr-50 d-none d-md-inline",
    attrs: {
      "icon": "SearchIcon"
    }
  }), _c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(" " + _vm._s(_vm.$t('suggestShopVJ')) + " ")])], 1)]) : _vm._e(), _vm.enableQuickName && !_vm.isHideFunction ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(234, 84, 85, 0.15)',
      expression: "'rgba(234, 84, 85, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "rounded-lg mr-50 py-50 py-md-1 d-flex-center border-0 px-1 px-lg-2",
    attrs: {
      "id": "id-icon-quick-input-multiple-pax",
      "variant": "warning"
    },
    on: {
      "click": _vm.handleShowModalQuickInput
    }
  }, [_c('feather-icon', {
    staticClass: "text-white mr-50 d-none d-md-inline",
    attrs: {
      "icon": "ZapIcon",
      "size": "14"
    }
  }), _c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(_vm._s(_vm.isMobileView ? 'Nhập nhanh' : _vm.$t('flight.quickInputPax')))])], 1) : _vm._e(), !_vm.isEmpty(_vm.selectedTrip) && !_vm.selectedTrip.every(function (trip) {
    return _vm.SOURCE_DISABLE_ADD_MORE_PAXS.includes(trip.source);
  }) && !_vm.isHideFunction ? _c('div', {
    staticClass: "mr-50"
  }, [_c('BookingAddMorePassenger', {
    attrs: {
      "pax-lists": _vm.passengerDataToAdd
    },
    on: {
      "changeQuantityPax": function changeQuantityPax(val) {
        return _vm.$emit('multipleQuantityPaxChange', val);
      }
    }
  })], 1) : _vm._e()], 1)], 1), _vm.selectedTrip.some(function (trip) {
    return ['VJ'].includes(trip.source);
  }) ? _c('b-row', {
    staticClass: "mx-0"
  }, [_c('b-alert', {
    staticClass: "p-50 my-50",
    staticStyle: {
      "width": "fit-content"
    },
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_c('span', {
    staticClass: "fw-700"
  }, [_vm._v(" Lưu ý: \" Theo yêu cầu của VietjetAir tất cả hành khách phải nhập ngày sinh. Trong trường hợp Quý AG để trống chúng tôi sẽ cung cấp dữ liệu ngẫu nhiên. BGT không chịu trách nhiệm nếu quý AG không nhập đầy đủ thông tin ! \" ")])])], 1) : _vm._e(), _vm._l(_vm.passengerDataToAdd, function (passenger, index) {
    return _c('b-card', {
      key: index,
      staticClass: "mb-50 mb-md-1",
      attrs: {
        "bg-variant": "light-secondary",
        "header-class": "px-25 py-0",
        "no-body": ""
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [_c('b-row', {
            staticClass: "w-100",
            attrs: {
              "no-gutters": ""
            }
          }, [_c('b-col', {
            staticClass: "w-100 d-flex-between text-nowrap px-75",
            attrs: {
              "cols": "12"
            }
          }, [_c('div', [_c('span', {
            class: "text-body ".concat(_vm.isMobileView ? 'font-small-4' : 'font-medium-2', " fw-700")
          }, [_vm._v(" " + _vm._s(_vm.resolveHeaderNamePassenger(passenger, index)) + " ")])]), _c('div', {
            staticClass: "d-flex align-items-end align-items-lg-center justify-content-center"
          }, [_c('div', {
            staticClass: "d-flex justify-content-around m-end"
          }, [!_vm.isEmpty(_vm.selectedTrip) && !_vm.selectedTrip.every(function (trip) {
            return _vm.SOURCE_DISABLE_ADD_MORE_PAXS.includes(trip.source);
          }) && !_vm.isHideFunction ? _c('b-button', {
            staticClass: "px-50 py-25",
            attrs: {
              "variant": "flat-danger"
            },
            on: {
              "click": function click($event) {
                return _vm.handleDeletePax(passenger);
              }
            }
          }, [_c('feather-icon', {
            staticClass: "fw-600",
            attrs: {
              "icon": "XIcon",
              "size": "20"
            }
          })], 1) : _vm._e()], 1)])]), _c('b-col', {
            staticClass: "px-0 px-md-50",
            attrs: {
              "cols": "2",
              "lg": "1"
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": "Honorific",
              "rules": "required",
              "vid": "Honorific-".concat(passenger.paxType, "-").concat(index)
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('b-form-group', {
                  staticClass: "mb-0",
                  attrs: {
                    "state": _vm.getValidationState(validationContext) === false ? false : null
                  }
                }, [_c('v-select', {
                  class: "select-size-sm honorific_select ".concat(_vm.isMobileView ? 'font-small-2' : 'font-medium-1', " font-weight-bolder"),
                  attrs: {
                    "id": "Honorific-".concat(passenger.paxType, "-").concat(index),
                    "options": _vm.titleOptions[passenger.paxType],
                    "label": "text",
                    "clearable": false,
                    "searchable": false,
                    "filterable": false,
                    "placeholder": "".concat(_vm.$t('flight.Honorific'), " (*)"),
                    "reduce": function reduce(val) {
                      return val.value;
                    }
                  },
                  scopedSlots: _vm._u([{
                    key: "option",
                    fn: function fn(data) {
                      return [_c('span', {}, [_vm._v(" " + _vm._s(_vm.$t(data.text)) + " ")])];
                    }
                  }, {
                    key: "selected-option",
                    fn: function fn(data) {
                      return [_c('span', {
                        staticClass: "text-nowrap"
                      }, [_vm._v(" " + _vm._s(_vm.$t(data.text)) + " ")])];
                    }
                  }], null, true),
                  model: {
                    value: passenger.title,
                    callback: function callback($$v) {
                      _vm.$set(passenger, "title", $$v);
                    },
                    expression: "passenger.title"
                  }
                }), validationContext.errors[0] ? _c('b-tooltip', {
                  attrs: {
                    "target": "Honorific-".concat(passenger.paxType, "-").concat(index),
                    "triggers": "hover focus",
                    "placement": "top",
                    "boundary": "viewport",
                    "variant": "danger",
                    "title": validationContext.errors[0]
                  }
                }, [_c('span', {
                  staticClass: "text-white py-25 mb-0"
                }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
              }
            }], null, true)
          })], 1), _c('b-col', {
            staticClass: "px-0 px-md-50",
            attrs: {
              "cols": "4",
              "lg": "5"
            }
          }, [_c('validation-provider', {
            attrs: {
              "rules": "required|alphaSpaces|min:".concat(_vm.selectedTrip.some(function (trip) {
                return ['1A'].includes(trip.source);
              }) ? '2' : '1'),
              "name": "Last Name",
              "vid": "lastName-".concat(passenger.paxType, "-").concat(index)
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('vue-autosuggest', {
                  attrs: {
                    "id": "lastName-".concat(passenger.paxType, "-").concat(index),
                    "suggestions": passenger.paxType ? _vm.paxFilteredSuggestions[passenger.paxType] : [],
                    "input-props": {
                      placeholder: "".concat(_vm.$t('flight.lastName'), " (*)"),
                      state: "".concat(_vm.getValidationState(validationContext) === false ? false : null),
                      class: "form-control text-body px-25 font-weight-bolder ".concat(_vm.isMobileView ? 'font-small-4' : 'font-medium-2', " ").concat(_vm.getValidationState(validationContext) === false ? 'is-invalid' : '')
                    },
                    "section-configs": _vm.sectionConfigs,
                    "get-suggestion-value": function getSuggestionValue(suggestion) {
                      return _vm.getSuggestionValue(suggestion, 'lastName');
                    }
                  },
                  on: {
                    "focus": function focus($event) {
                      return _vm.setCurrentPaxIndex(index);
                    },
                    "input": function input($event) {
                      passenger.lastName = _vm.formatterInput($event);
                      _vm.debounceSearchCustomerContact(passenger.lastName);
                    },
                    "blur": function blur() {
                      passenger.lastName = passenger.lastName.trim();
                    }
                  },
                  scopedSlots: _vm._u([{
                    key: "default",
                    fn: function fn(_ref) {
                      var suggestion = _ref.suggestion;
                      return [_c('div', {
                        staticClass: "cursor-pointer"
                      }, [_c('b-row', [_c('b-col', {
                        staticClass: "text-nowrap font-italic small",
                        attrs: {
                          "cols": "12"
                        }
                      }, [_vm._v(" " + _vm._s(suggestion.item.type ? _vm.$t("reservation.".concat(suggestion.item.type)) : '') + " ")]), _c('b-col', {
                        attrs: {
                          "cols": "12"
                        }
                      }, [_c('b', {
                        domProps: {
                          "innerHTML": _vm._s(_vm.highlightSearchString(_vm.getLocaleFullName(suggestion.item), passenger.lastName))
                        }
                      })]), _c('b-col', {
                        staticClass: "text-nowrap",
                        attrs: {
                          "cols": "12"
                        }
                      }, [_vm._v(" " + _vm._s(suggestion.item.birthDay ? suggestion.item.birthDay.slice(0, 10) : '') + " ")])], 1)], 1)];
                    }
                  }], null, true),
                  model: {
                    value: passenger.lastName,
                    callback: function callback($$v) {
                      _vm.$set(passenger, "lastName", $$v);
                    },
                    expression: "passenger.lastName"
                  }
                }), validationContext.errors[0] ? _c('b-tooltip', {
                  attrs: {
                    "target": "lastName-".concat(passenger.paxType, "-").concat(index),
                    "triggers": "hover focus",
                    "placement": "top",
                    "boundary": "viewport",
                    "variant": "danger",
                    "title": validationContext.errors[0]
                  }
                }, [_c('span', {
                  staticClass: "text-white py-25 mb-0"
                }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()];
              }
            }], null, true)
          })], 1), _c('b-col', {
            staticClass: "px-0 px-md-50",
            attrs: {
              "cols": "6",
              "lg": "6"
            }
          }, [_c('validation-provider', {
            attrs: {
              "name": "First Name",
              "rules": "required|alphaSpaces|min:".concat(_vm.selectedTrip.some(function (trip) {
                return ['1A'].includes(trip.source);
              }) ? '2' : '1'),
              "vid": "firstName-".concat(passenger.paxType, "-").concat(index)
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(validationContext) {
                return [_c('vue-autosuggest', {
                  attrs: {
                    "id": "firstName-".concat(passenger.paxType, "-").concat(index),
                    "suggestions": passenger.paxType ? _vm.paxFilteredSuggestions[passenger.paxType] : [],
                    "input-props": {
                      placeholder: "".concat(_vm.$t('flight.firstName'), " (*)"),
                      state: "".concat(_vm.getValidationState(validationContext) === false ? false : null),
                      class: "form-control text-body px-25 ".concat(_vm.isMobileView ? 'font-small-4' : 'font-medium-2', " font-weight-bolder ").concat(_vm.getValidationState(validationContext) === false ? 'is-invalid' : '')
                    },
                    "section-configs": _vm.sectionConfigs,
                    "get-suggestion-value": function getSuggestionValue(suggestion) {
                      return _vm.getSuggestionValue(suggestion, 'firstName');
                    }
                  },
                  on: {
                    "focus": function focus($event) {
                      return _vm.setCurrentPaxIndex(index);
                    },
                    "input": function input($event) {
                      passenger.firstName = _vm.formatterInput($event);
                      _vm.debounceSearchCustomerContact(passenger.firstName);
                    },
                    "blur": function blur() {
                      passenger.firstName = passenger.firstName.trim();
                    }
                  },
                  scopedSlots: _vm._u([{
                    key: "default",
                    fn: function fn(_ref2) {
                      var suggestion = _ref2.suggestion;
                      return [_c('div', {
                        staticClass: "cursor-pointer"
                      }, [_c('b-row', [_c('b-col', {
                        staticClass: "text-nowrap font-italic small",
                        attrs: {
                          "cols": "12"
                        }
                      }, [_vm._v(" " + _vm._s(suggestion.item.type ? _vm.$t("reservation.".concat(suggestion.item.type)) : '') + " ")]), _c('b-col', {
                        attrs: {
                          "cols": "12"
                        }
                      }, [_c('b', {
                        domProps: {
                          "innerHTML": _vm._s(_vm.highlightSearchString(_vm.getLocaleFullName(suggestion.item), passenger.firstName))
                        }
                      })]), _c('b-col', {
                        staticClass: "text-nowrap",
                        attrs: {
                          "cols": "12"
                        }
                      }, [_vm._v(" " + _vm._s(suggestion.item.birthDay ? suggestion.item.birthDay.slice(0, 10) : '') + " ")])], 1)], 1)];
                    }
                  }], null, true),
                  model: {
                    value: passenger.firstName,
                    callback: function callback($$v) {
                      _vm.$set(passenger, "firstName", $$v);
                    },
                    expression: "passenger.firstName"
                  }
                }), validationContext.errors[0] ? _c('b-tooltip', {
                  attrs: {
                    "target": "firstName-".concat(passenger.paxType, "-").concat(index),
                    "triggers": "hover focus",
                    "placement": "top",
                    "boundary": "viewport",
                    "variant": "danger",
                    "title": validationContext.errors[0]
                  }
                }, [_c('span', {
                  staticClass: "text-white py-25 mb-0"
                }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()];
              }
            }], null, true)
          })], 1), _c('b-col', {
            attrs: {
              "cols": "12 d-flex justify-content-end"
            }
          }, [_c('div', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: "collapse-".concat(passenger.paxType, "-").concat(index),
              expression: "`collapse-${passenger.paxType}-${index}`"
            }],
            staticClass: "d-flex-center px-50 py-25 mr-50 hover-underline"
          }, [_c('feather-icon', {
            staticClass: "text-body",
            attrs: {
              "icon": "ChevronDownIcon",
              "size": "16"
            }
          }), _c('span', {
            staticClass: "font-small-3 text-info ml-25"
          }, [_vm._v(" " + _vm._s(_vm.$t('flight.Details')) + " ")])], 1)])], 1)];
        },
        proxy: true
      }], null, true)
    }, [_c('b-collapse', {
      attrs: {
        "id": "collapse-".concat(passenger.paxType, "-").concat(index),
        "visible": passenger.paxType === 'INFANT' || _vm.getValidateBirthday(passenger.paxType, passenger).includes('required') ? true : false
      }
    }, [_c('div', {
      staticClass: "pb-50"
    }, [_c('b-row', {
      staticClass: "mx-0"
    }, [_c('b-col', {
      class: _vm.isMobileView ? 'px-25' : '',
      attrs: {
        "cols": "12",
        "sm": "6"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": _vm.$t('flight.dob'),
        "rules": passenger.paxType === 'ADULT' && ['MSTR', 'MISS'].includes(passenger.title) ? _vm.getValidateBirthday('CHILD', passenger) : _vm.getValidateBirthday(passenger.paxType, passenger),
        "vid": "dob-".concat(passenger.paxType, "-").concat(passenger.paxId)
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(validationContext) {
          return [_c('b-form-group', {
            staticClass: "mb-0",
            attrs: {
              "id": "bd-".concat(passenger.paxType, "-").concat(passenger.paxId)
            }
          }, [_c('flat-pickr', {
            staticClass: "d-none",
            model: {
              value: passenger.birthday,
              callback: function callback($$v) {
                _vm.$set(passenger, "birthday", $$v);
              },
              expression: "passenger.birthday"
            }
          }), _c('div', {
            staticClass: "form-control p-0",
            class: validationContext.errors[0] ? 'is-invalid' : ''
          }, [_c('flat-pickr', {
            staticClass: "form-control px-50 py-0",
            style: {
              height: '30px'
            },
            attrs: {
              "id": "dob-".concat(passenger.paxType, "-").concat(passenger.paxId),
              "name": _vm.$t('flight.dob'),
              "config": Object.assign({}, _vm.configFlatPickr, {
                altInputClass: 'form-control input font_birthday_passenger  font-weight-bolder px-50 py-0 border-transparent'
              }),
              "placeholder": "".concat(_vm.$t('flight.dob'), " ").concat(_vm.getValidateBirthday(passenger.paxType, passenger).includes('required') ? '(*)' : '')
            },
            model: {
              value: passenger.birthday,
              callback: function callback($$v) {
                _vm.$set(passenger, "birthday", $$v);
              },
              expression: "passenger.birthday"
            }
          })], 1), validationContext.errors[0] ? _c('b-tooltip', {
            attrs: {
              "target": "bd-".concat(passenger.paxType, "-").concat(passenger.paxId),
              "triggers": "hover focus",
              "placement": "top",
              "boundary": "viewport",
              "variant": "danger",
              "title": validationContext.errors[0]
            }
          }, [_c('span', {
            staticClass: "text-white py-25 mb-0"
          }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
        }
      }], null, true)
    })], 1), passenger.paxType === 'INFANT' ? _c('b-col', {
      class: _vm.isMobileView ? 'px-25' : '',
      attrs: {
        "cols": "12",
        "sm": "6"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": "Người lớn đi cùng",
        "rules": passenger.paxType === 'INFANT' ? "required|duplicateParentId:".concat(_vm.parentSelected.toString()) : '',
        "vid": "parent-id-select-".concat(passenger.paxId)
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(validationContext) {
          return [_c('b-form-group', {
            staticClass: "mb-0",
            attrs: {
              "size": "sm",
              "state": _vm.getValidationState(validationContext) === false ? false : null
            }
          }, [passenger.paxType === 'INFANT' ? _c('v-select', {
            staticClass: "honorific_select",
            attrs: {
              "id": "parent-id-select-".concat(passenger.paxId),
              "options": _vm.parentOptions,
              "reduce": function reduce(val) {
                return val.paxId;
              },
              "clearable": false,
              "placeholder": "".concat(_vm.$t('flight.selectAdult'), " (*)"),
              "label": "firstName",
              "disabled": _vm.isDisabledSelectParentPaxId
            },
            scopedSlots: _vm._u([{
              key: "option",
              fn: function fn(data) {
                return [_c('span', {
                  class: "".concat(_vm.isMobileView ? 'font-small-2' : 'font-small-3')
                }, [_vm._v(" " + _vm._s(data.firstName && data.lastName ? "".concat(data.title, " ").concat(data.lastName, " ").concat(data.firstName) : "".concat(data.paxType, " #").concat(data.paxId)) + " ")])];
              }
            }, {
              key: "selected-option",
              fn: function fn(data) {
                return [_c('span', {
                  class: "".concat(_vm.isMobileView ? 'font-small-2' : 'font-small-3')
                }, [_vm._v(" " + _vm._s(data.firstName && data.lastName ? "".concat(data.title, " ").concat(data.lastName, " ").concat(data.firstName) : "".concat(data.paxType, " #").concat(data.paxId)) + " ")])];
              }
            }], null, true),
            model: {
              value: passenger.parentPaxId,
              callback: function callback($$v) {
                _vm.$set(passenger, "parentPaxId", $$v);
              },
              expression: "passenger.parentPaxId"
            }
          }) : _vm._e(), validationContext.errors[0] ? _c('b-tooltip', {
            attrs: {
              "target": "parent-id-select-".concat(passenger.paxId),
              "triggers": "hover focus",
              "placement": "top",
              "boundary": "viewport",
              "variant": "danger",
              "title": validationContext.errors[0]
            }
          }, [_c('span', {
            staticClass: "text-white py-25 mb-0"
          }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e(), _c('div', [_c('i', {
            class: "text-danger font-weight-bolder ".concat(_vm.isMobileView ? 'font-small-2' : 'font-small-3')
          }, [_vm._v(" * " + _vm._s(_vm.$t('flight.requiredAdult')) + " ")])])], 1)];
        }
      }], null, true)
    })], 1) : _vm._e()], 1), _c('b-row', {
      staticClass: "mx-0 pb-50"
    }, [_c('b-col', {
      class: "".concat(passenger.paxType === 'INFANT' ? 'd-none' : 'd-block', " ").concat(_vm.isMobileView ? 'px-25' : ''),
      attrs: {
        "cols": "12",
        "sm": "6"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": "Member Fly",
        "rules": "",
        "vid": "memberFly-".concat(passenger.paxType, "-").concat(index)
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(validationContext) {
          return [_c('b-input-group', {
            staticClass: "border rounded",
            attrs: {
              "id": "memberFly-".concat(passenger.paxType, "-").concat(index)
            }
          }, [_c('b-input-group-prepend', {
            class: _vm.isMobileView ? 'flex-wrap' : '',
            attrs: {
              "id": "memberFly-".concat(passenger.paxType, "-").concat(index)
            }
          }, [_c('v-select', {
            staticClass: "membership-airline-select rounded",
            class: _vm.isMobileView ? 'border-secondary' : '',
            staticStyle: {
              "width": "200px",
              "max-height": "38px"
            },
            attrs: {
              "id": "memberFly-".concat(passenger.paxType, "-").concat(index),
              "options": _vm.getAirlineOptionsGroupByTrips(_vm.selectedTrip, passenger.memberFly.airline, index),
              "label": "text",
              "clearable": false,
              "placeholder": "".concat(_vm.$t('flight.airline')),
              "reduce": function reduce(val) {
                return val.value;
              }
            },
            scopedSlots: _vm._u([{
              key: "option",
              fn: function fn(data) {
                return [_c('span', {
                  class: "".concat(_vm.isMobileView ? 'font-small-2' : 'font-small-3', " my-25 font-weight-bold")
                }, [_vm._v(" " + _vm._s(data.text) + " ")])];
              }
            }, {
              key: "selected-option",
              fn: function fn(data) {
                return [_c('span', {
                  class: "font-weight-bolder text-nowrap text-truncate ".concat(_vm.isMobileView ? 'font-small-2' : 'font-small-3'),
                  staticStyle: {
                    "max-width": "200px"
                  }
                }, [_vm._v(" " + _vm._s(data.text) + " ")])];
              }
            }], null, true),
            model: {
              value: passenger.memberFly.airline,
              callback: function callback($$v) {
                _vm.$set(passenger.memberFly, "airline", $$v);
              },
              expression: "passenger.memberFly.airline"
            }
          })], 1), _c('b-form-input', {
            class: "\n                    ".concat(_vm.isMobileView ? 'border-secondary' : '', "\n                    "),
            staticStyle: {
              "min-width": "145px"
            },
            attrs: {
              "id": "cardNumber-".concat(passenger.paxType, "-").concat(index),
              "type": "number",
              "placeholder": "".concat(_vm.$t('flight.memberFly')),
              "lazy-formatter": "",
              "formatter": _vm.formatterCardNumber,
              "state": _vm.getValidationState(validationContext) === false ? false : null
            },
            on: {
              "change": function change(val) {
                return passenger.memberFly.cardNumber = Number(val);
              }
            },
            model: {
              value: passenger.memberFly.cardNumber,
              callback: function callback($$v) {
                _vm.$set(passenger.memberFly, "cardNumber", $$v);
              },
              expression: "passenger.memberFly.cardNumber"
            }
          })], 1)];
        }
      }], null, true)
    })], 1), !_vm.isEmpty(_vm.selectedTrip) && _vm.selectedTrip.some(function (trip) {
      return ['1S', 'VN1A', 'QH'].includes(trip.source);
    }) && !_vm.isHideFunction ? _c('b-button', {
      staticClass: "py-25 mt-50 mt-md-0",
      class: passenger.paxType !== 'ADULT' ? 'd-none' : 'd-block',
      attrs: {
        "id": "memberFlyBtn-".concat(passenger.paxType, "-").concat(index),
        "variant": "flat-info"
      },
      on: {
        "click": function click($event) {
          return _vm.handleOpenModalRegisterMembershipCard({
            index: index,
            passenger: passenger
          }, $event);
        }
      }
    }, [_c('div', {
      staticClass: "d-flex-center"
    }, [_c('feather-icon', {
      staticClass: "mr-50",
      attrs: {
        "icon": "PlusSquareIcon",
        "size": "20"
      }
    }), _c('div', [_vm._v(_vm._s(_vm.$t('flight.registerMembershipCard')))])], 1)]) : _vm._e()], 1), !_vm.isDomesticTrips && !['INFANT'].includes(passenger.paxType) ? _c('b-row', {
      staticClass: "px-1"
    }, [_c('b-col', {
      class: _vm.isMobileView ? 'px-25' : '',
      attrs: {
        "md": "3"
      }
    }, [_c('validation-provider', {
      attrs: {
        "rules": _vm.getValidatePassport(passenger),
        "name": _vm.$t('flight.Passport'),
        "vid": "passport-".concat(passenger.paxType, "-").concat(index)
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(validationContext) {
          return [_c('b-form-group', {
            staticClass: "mb-0",
            attrs: {
              "id": "passport-".concat(passenger.paxType, "-").concat(index)
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "passport-".concat(passenger.paxType, "-").concat(index),
              "state": _vm.getValidationState(validationContext) === false ? false : null,
              "lazy-formatter": "",
              "formatter": _vm.trimAllInputAndUpper,
              "size": "sm",
              "placeholder": "".concat(_vm.$t('flight.Passport'), " ").concat(_vm.getValidatePassport(passenger).includes('required') ? '(*)' : '')
            },
            model: {
              value: passenger.PaxDocuments[0].number,
              callback: function callback($$v) {
                _vm.$set(passenger.PaxDocuments[0], "number", $$v);
              },
              expression: "passenger.PaxDocuments[0].number"
            }
          }), validationContext.errors[0] ? _c('b-tooltip', {
            attrs: {
              "target": "passport-".concat(passenger.paxType, "-").concat(index),
              "triggers": "hover focus",
              "placement": "top",
              "boundary": "viewport",
              "variant": "danger",
              "title": validationContext.errors[0]
            }
          }, [_c('span', {
            staticClass: "text-white py-25 mb-0"
          }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
        }
      }], null, true)
    })], 1), _c('b-col', {
      class: _vm.isMobileView ? 'px-25' : '',
      attrs: {
        "md": "3"
      }
    }, [_c('validation-provider', {
      attrs: {
        "rules": _vm.getValidatePassport(passenger),
        "name": _vm.$t('flight.Nationality'),
        "vid": "nationality-".concat(passenger.paxType, "-").concat(index)
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(validationContext) {
          return [_c('IAmCountrySelect', {
            attrs: {
              "id": "nationality-".concat(passenger.paxType, "-").concat(index),
              "placeholder": "M\xE3 qu\u1ED1c gia ".concat(_vm.getValidatePassport(passenger).includes('required') ? '(*)' : ''),
              "contextErrors": validationContext.errors[0],
              "size": 'sm'
            },
            model: {
              value: passenger.PaxDocuments[0].nationality,
              callback: function callback($$v) {
                _vm.$set(passenger.PaxDocuments[0], "nationality", $$v);
              },
              expression: "passenger.PaxDocuments[0].nationality"
            }
          })];
        }
      }], null, true)
    })], 1), _c('b-col', {
      class: _vm.isMobileView ? 'px-25' : '',
      attrs: {
        "md": "3"
      }
    }, [_c('validation-provider', {
      attrs: {
        "rules": _vm.getValidatePassport(passenger),
        "name": "Nơi cấp",
        "vid": "passport-place-issued-".concat(passenger.paxType, "-").concat(index)
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(validationContext) {
          return [_c('IAmCountrySelect', {
            attrs: {
              "id": "passport-place-issued-".concat(passenger.paxType, "-").concat(index),
              "placeholder": "N\u01A1i c\u1EA5p ".concat(_vm.getValidatePassport(passenger).includes('required') ? '(*)' : ''),
              "contextErrors": validationContext.errors[0],
              "size": 'sm'
            },
            model: {
              value: passenger.PaxDocuments[0].isCountry,
              callback: function callback($$v) {
                _vm.$set(passenger.PaxDocuments[0], "isCountry", $$v);
              },
              expression: "passenger.PaxDocuments[0].isCountry"
            }
          })];
        }
      }], null, true)
    })], 1), _c('b-col', {
      class: _vm.isMobileView ? 'px-25' : '',
      attrs: {
        "md": "3"
      }
    }, [_c('validation-provider', {
      attrs: {
        "rules": _vm.getValidatePassport(passenger),
        "name": _vm.$t('flight.expirationDate'),
        "vid": "passport-expirationDate-".concat(passenger.paxType, "-").concat(index)
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(validationContext) {
          return [_c('b-form-group', {
            staticClass: "mb-0",
            attrs: {
              "id": "passport-exDate-".concat(passenger.paxType, "-").concat(index)
            }
          }, [_c('flat-pickr', {
            staticClass: "d-none",
            model: {
              value: passenger.PaxDocuments[0].endDate,
              callback: function callback($$v) {
                _vm.$set(passenger.PaxDocuments[0], "endDate", $$v);
              },
              expression: "passenger.PaxDocuments[0].endDate"
            }
          }), _c('div', {
            staticClass: "form-control form-control-sm  p-0",
            class: validationContext.errors[0] ? 'is-invalid' : ''
          }, [_c('flat-pickr', {
            staticClass: "form-control form-control-sm  px-50 py-0",
            style: {
              height: '30px'
            },
            attrs: {
              "id": "passport-expirationDate-".concat(passenger.paxType, "-").concat(index),
              "name": _vm.$t('flight.expirationDate'),
              "config": Object.assign({}, _vm.configFlatPickrPassport, {
                altInputClass: 'form-control form-control-sm  input px-50 py-0 border-transparent'
              }),
              "placeholder": "".concat(_vm.$t('flight.expirationDate'), " ").concat(_vm.getValidatePassport(passenger).includes('required') ? '(*)' : '')
            },
            model: {
              value: passenger.PaxDocuments[0].endDate,
              callback: function callback($$v) {
                _vm.$set(passenger.PaxDocuments[0], "endDate", $$v);
              },
              expression: "passenger.PaxDocuments[0].endDate"
            }
          })], 1), validationContext.errors[0] ? _c('b-tooltip', {
            attrs: {
              "target": "passport-exDate-".concat(passenger.paxType, "-").concat(index),
              "triggers": "hover focus",
              "placement": "top",
              "boundary": "viewport",
              "variant": "danger",
              "title": validationContext.errors[0]
            }
          }, [_c('span', {
            staticClass: "text-white py-25 mb-0"
          }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
        }
      }], null, true)
    })], 1), _c('b-col', {
      staticClass: "mt-25",
      class: _vm.isMobileView ? 'px-25' : '',
      attrs: {
        "md": "3"
      }
    }, [_c('validation-provider', {
      attrs: {
        "rules": _vm.getValidatePassport(passenger, 'gender'),
        "name": _vm.$t('flight.gender'),
        "vid": "passport-gender-".concat(passenger.paxType, "-").concat(index)
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(validationContext) {
          return [_c('b-form-group', {
            staticClass: "mb-0",
            attrs: {
              "id": "passport-gender-".concat(passenger.paxType, "-").concat(index)
            }
          }, [_c('v-select', {
            staticClass: "w-100 bg-white form-control-sm p-0",
            class: validationContext.errors[0] ? 'border-danger-select' : '',
            attrs: {
              "reduce": function reduce(val) {
                return val.value;
              },
              "label": "label",
              "options": _vm.genderOptions.filter(function (item) {
                return item.value !== 'OTHER';
              }),
              "placeholder": "".concat(_vm.$t('flight.gender'), " ").concat(_vm.getValidatePassport(passenger, 'gender').includes('required') ? '(*)' : '')
            },
            scopedSlots: _vm._u([{
              key: "option",
              fn: function fn(data) {
                return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
              }
            }, {
              key: "selected-option",
              fn: function fn(data) {
                return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
              }
            }, {
              key: "no-options",
              fn: function fn() {
                return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
              },
              proxy: true
            }], null, true),
            model: {
              value: passenger.PaxDocuments[0].gender,
              callback: function callback($$v) {
                _vm.$set(passenger.PaxDocuments[0], "gender", $$v);
              },
              expression: "passenger.PaxDocuments[0].gender"
            }
          }), validationContext.errors[0] ? _c('b-tooltip', {
            attrs: {
              "target": "passport-gender-".concat(passenger.paxType, "-").concat(index),
              "triggers": "hover focus",
              "placement": "top",
              "boundary": "viewport",
              "variant": "danger",
              "title": validationContext.errors[0]
            }
          }, [_c('span', {
            staticClass: "text-white py-25 mb-0"
          }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])]) : _vm._e()], 1)];
        }
      }], null, true)
    })], 1)], 1) : _vm._e()], 1)])], 1);
  }), _c('MembershipCardModal', {
    attrs: {
      "member-fly-data": _vm.memberFlyData,
      "data-search-flight": _vm.dataSearchFlight,
      "pax-data": _vm.paxDataSelect,
      "airline-lists": _vm.getAirlineList,
      "place": 'create_booking'
    },
    on: {
      "update:memberFlyData": function updateMemberFlyData($event) {
        _vm.memberFlyData = $event;
      },
      "update:member-fly-data": function updateMemberFlyData($event) {
        _vm.memberFlyData = $event;
      }
    }
  }), _c('ModalQuickInputMultiPax', {
    attrs: {
      "max-adults": _vm.getMaxLengthPax('adult'),
      "max-children": _vm.getMaxLengthPax('child'),
      "max-infants": _vm.getMaxLengthPax('infant'),
      "is-create-group-booking": _vm.isCreateGroupBooking
    },
    on: {
      "addMuliplePassengers": _vm.addMuliplePassengers
    }
  }), true ? _c('SuggestShopVJModal', {
    attrs: {
      "passenger-data-to-add": _vm.passengerDataToAdd,
      "airline-code": _vm.selectedTrip[0].airline
    },
    on: {
      "update:passengerDataToAdd": function updatePassengerDataToAdd($event) {
        _vm.passengerDataToAdd = $event;
      },
      "update:passenger-data-to-add": function updatePassengerDataToAdd($event) {
        _vm.passengerDataToAdd = $event;
      },
      "update:airlineCode": function updateAirlineCode($event) {
        return _vm.$set(_vm.selectedTrip[0], "airline", $event);
      },
      "update:airline-code": function updateAirlineCode($event) {
        return _vm.$set(_vm.selectedTrip[0], "airline", $event);
      },
      "set-visible": _vm.onSetVisible
    }
  }) : _vm._e()], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }